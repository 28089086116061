import React from 'react'
import { Container, Flex, Box, css, Text } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'
import { AiFillStar } from 'react-icons/ai'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import Tabs from '@solid-ui-components/Tabs'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  avatar: {
    bg: `#FFF`,
    borderTopColor: `#FFF`,
    borderTop: `1px solid #FFF`,
    borderTopStyle: `solid`,
    borderRadius: `4px`,
    boxSizing: `content-box`,
    verticalAlign: `baseline`,
    '@media (max-width: 639px)': {
      display: 'none',
    }
  },
  title: {
    fontSize: '30px',
    color: '#2A205E',
    '@media (max-width: 991px)': {
      textAlign: 'left',
    },
  },
  subTitle: {
    paddingLeft: '2rem',
    fontSize: '17px'
  },
  contentTitle : { 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 991px)': {
      justifyContent: 'center',
      alignItems: 'baseline',
    },
    '@media (max-width: 765px)': {
      justifyContent: 'flex-start',
    },
  },
  bold: {
    fontWeight: 600
  },
  contentContainer: {
    padding: '1rem',
    position: `relative`, 
    borderLeft: '12px solid #01CEAA', 
    // '@media (max-width: 1300px)': {
    //   width: '120%', 
    //   marginLeft: '-2rem'
    // },
    // '@media (max-width: 991px)': {
    //   width: '100%',
    //   marginLeft: 0
    // }
  },
  // containerText: {
  //   '@media (max-width: 1300px)': {
  //     width: '80%',
  //     margin: 'auto'
  //   },
  //   '@media (max-width: 991px)': {
  //     width: '100%'
  //   }
  // }
}  

const TestimonialsBlock03 = ({
  content: { text, collection, buttons, icon },
  reverse
}) => (
  <Container>
    <Flex
      sx={{
        alignItems: `flex-start`,
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ],
        mx: [null, null, null, -4]
      }}
    >
      <Box
        sx={{
          flexBasis: `65%`,
          mx: [null, null, null, 4],
          [reverse ? 'mt' : 'mb']: [5, null, null, 0],
        }}
      >
        <Tabs variant='dots' position='bottom' space={3} autoplay autoplayInterval={10000}>
          {collection?.map(({ container, text, avatar }, index) => (
            <ContentContainer
              content={container}
              variant='cards.paper'
              key={`item-${index}`}
              sx={styles.contentContainer}
            >
              <Flex
                sx={{
                  alignItems: `center`,
                  position: `relative`,
                  flexWrap: `wrap`,
                  zIndex: 1
                }}
              >
                <Box sx={{ width: 150 }}>
                  <ContentImages
                    content={{ images: [avatar] }}
                    sx={styles.avatar}
                    imageEffect='fadeInRotate'
                  />
                </Box>
                <Box sx={{ flex: [`100%`, 1], ml: [0, 4] }}>
                  <Reveal effect='fadeInUp'>
                    <ContentText content={text} />
                    {Array.from({ length: 5 }, (_, i) => (
                      <Reveal
                        key={`item-${i}`}
                        effect='fadeInRotate'
                        delay={0.2 * (i + 1)}
                        css={css({ display: `inline-block`, mt: 3 })}
                      >
                        <AiFillStar
                          css={css({ color: `beta`, size: `icon.xs` })}
                        />
                      </Reveal>
                    ))}
                  </Reveal>
                </Box>
              </Flex>
            </ContentContainer>
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          flexBasis: `35%`,
          mx: [null, null, null, 4],
          textAlign: [`left`, null, null, `left`]
        }}
      >
        <Reveal effect={reverse ? 'fadeInLeft' : 'fadeInRight'}>
        <Box sx={styles.containerText}>
          <Box sx={styles.contentTitle}>
            <Text sx={styles.title}>
              <Icon content={icon} mr='1' size='10px' round />
              Nos <span style={{ fontWeight: 600 }}>recomiendan</span>
            </Text>
          </Box>  
            <Text sx={styles.subTitle}>
            Durante más de <strong>10 años</strong> trabajamos en propuestas <strong>STEAM customizadas</strong> para nuestros clientes, tanto del sector público como privado. Además acompañamos políticas públicas con <strong>soluciones integrales desarrolladas a medida, que han impactado en más de 1,5 millones de estudiantes.</strong> Los mayores referentes en tecnología y educación reconocen nuestra labor.
            </Text>
            {buttons && (
              <>
                <Divider space={3} />
                <ContentButtons content={buttons} />
              </>
            )}
        </Box>
        </Reveal>
      </Box>
    </Flex>
  </Container>
)

export default WithDefaultContent(TestimonialsBlock03)
