import React, { useState } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios'

export const Newsletter = () => {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formError, setFormError] = useState(false)
    const [email, setEmail] = useState('')

    const handleSubmit = event => {
        event.preventDefault();

        const url = 'https://58079a7a.sibforms.com/serve/MUIEAN2kckSBqi087IoQO8Yylu-aIluJ10QkETLP1MYCrs2jaquno8BLB3FPfcNeLpD6tgZOOsIh9kXwy0P7boyhTgo_qRtQTpv9lHTUZmSFZwtRZ1r5R9AvjaO32JGHuhNC8aEErMOhIeo85MCi96TWn4Yr-CmGca03dCvX3FmH9UrzLWH44Qzn7mL6_mJ7MsdIfjC3r7G-oVai'

        // axios.post(url)
        //     .then(response => console.log(response))


        // const requestOptions = {
        //     method: 'POST',
        //     body: JSON.stringify({ email })
        // };
        // fetch(url, requestOptions)
        // .then(res => res)
        // .then(data => console.log('SOY DATA', data));

            // .then(() => setFormSubmitted(true))
            // .catch(() => setFormError(true))
    };

    return (
        <div>
            {/* Begin Sendinblue Form */}
            {/* START - We recommend to place the below code in head tag of your website html  */}

            <style dangerouslySetInnerHTML={{ __html: "\n  @font-face {\n    font-display: block;\n    font-family: Roboto;\n    src: url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/7529907e9eaf8ebb5220c5f9850e3811.woff2) format(\"woff2\"), url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/25c678feafdc175a70922a116c9be3e7.woff) format(\"woff\")\n  }\n  @font-face {\n    font-display: fallback;\n    font-family: Roboto;\n    font-weight: 600;\n    src: url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/6e9caeeafb1f3491be3e32744bc30440.woff2) format(\"woff2\"), url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/71501f0d8d5aa95960f6475d5487d4c2.woff) format(\"woff\")\n  }\n  @font-face {\n    font-display: fallback;\n    font-family: Roboto;\n    font-weight: 700;\n    src: url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/3ef7cf158f310cf752d5ad08cd0e7e60.woff2) format(\"woff2\"), url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/ece3a1d82f18b60bcce0211725c476aa.woff) format(\"woff\")\n  }\n  #sib-container input:-ms-input-placeholder {\n    text-align: left;\n    font-family: \"Helvetica\", sans-serif;\n    color: #C0CCDA;\n  }\n  #sib-container input::placeholder {\n    text-align: left;\n    font-family: \"Helvetica\", sans-serif;\n    color: #C0CCDA;\n  }\n  #sib-container textarea::placeholder {\n    text-align: left;\n    font-family: \"Helvetica\", sans-serif;\n    color: #C0CCDA;\n  }\n" }} />
                <link rel="stylesheet" href="https://sibforms.com/forms/end-form/build/sib-styles.css" />

            {/*  END - We recommend to place the above code in head tag of your website html */}
            {/* START - We recommend to place the below code where you want the form in your website html  */}
            <div className="sib-form" style={{ textAlign: 'left', backgroundColor: 'transparent', padding: 0 }}>
                <div id="sib-form-container" className="sib-form-container">
                    {/* <div id="error-message" className="sib-form-message-panel" style={{ fontSize: '16px', textAlign: 'left', fontFamily: '"Helvetica", sans-serif', color: '#661d1d', backgroundColor: '#ffeded', borderRadius: '3px', borderColor: '#ff4949', maxWidth: '350px' }}>
                        <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
                            <svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
                                <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
                            </svg>
                            <span className="sib-form-message-panel__inner-text">
                                No hemos podido validar su suscripción.
                            </span>
                        </div>
                    </div> */}
                    <div />
                    {/* <div id="success-message" className="sib-form-message-panel" style={{ fontSize: '16px', textAlign: 'left', fontFamily: '"Helvetica", sans-serif', color: '#085229', backgroundColor: '#e7faf0', borderRadius: '3px', borderColor: '#13ce66', maxWidth: '350px' }}>
                        <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
                            <svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
                                <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
                            </svg>
                            <span className="sib-form-message-panel__inner-text">
                                Se ha realizado su suscripción.
                            </span>
                        </div>
                    </div> */}
                    <div />
                    <div id="sib-container" className="sib-container--medium sib-container--horizontal" style={{ textAlign: 'left', backgroundColor: 'transparent', maxWidth: '400px', borderRadius: '3px', borderWidth: '1px', borderColor: 'transparent', borderStyle: 'solid', padding: 0 }}>
                        <form id="sib-form" style={{ padding: '0px' }} onSubmit={handleSubmit} data-type="subscription">
                            <div style={{ padding: '8px 0' }}>
                                <div className="sib-input sib-form-block">
                                    <div className="form__entry entry_block">
                                        <div className="form__label-row form__label-row--horizontal">
                                            <div className="entry__field" style={{ borderRadius: '4px', height: '50px' }}>
                                                <input className="input" type="text" value={email} onChange={(e) => setEmail(e.target.value)} id="EMAIL" name="EMAIL" autoComplete="off" placeholder="Correo electrónico" data-required="true" required />
                                            </div>
                                        </div>
                                        <label className="entry__error entry__error--primary" style={{ fontSize: '16px', textAlign: 'left', fontFamily: '"Helvetica", sans-serif', color: '#661d1d', backgroundColor: '#ffeded', borderRadius: '3px', borderColor: '#ff4949' }}>
                                        </label>
                                        {formSubmitted &&
                                            <div style={{
                                                fontSize: '16px',
                                                textAlign: 'left',
                                                fontFamily: '"Helvetica", sans-serif',
                                                color: '#085229',
                                                backgroundColor: '#e7faf0',
                                                borderRadius: '3px',
                                                borderColor: '#13ce66',
                                                maxWidth: '350px',
                                                padding: '10px',
                                                border: '1px solid #13CE66',
                                                borderRadius: '8px'
                                            }}>
                                                <span>Se ha realizado su suscripción.</span>
                                            </div>
                                        }
                                        {formError &&
                                            <div style={{
                                                fontSize: '16px',
                                                textAlign: 'left',
                                                fontFamily: '"Helvetica", sans-serif',
                                                color: '#661d1d',
                                                backgroundColor: '#ffeded',
                                                borderRadius: '3px',
                                                borderColor: '#ff4949',
                                                maxWidth: '350px',
                                                padding: '10px',
                                                border: '1px solid',
                                                borderRadius: '8px'
                                            }}>
                                                <span>No hemos podido validar su suscripción.</span>
                                            </div>

                                        }
                                        <label className="entry__specification" style={{ fontSize: '18px', textAlign: 'left', fontFamily: '"Helvetica", sans-serif', color: '#FFFFFF' }}>
                                            Introduzca su correo electrónico
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: '8px 0' }}>
                                <div className="sib-form-block" style={{ textAlign: 'left' }}>
                                    <button className="sib-form-block__button sib-form-block__button-with-loader" style={{ fontSize: '16px', textAlign: 'left', fontWeight: 700, fontFamily: '"Helvetica", sans-serif', color: '#FFFFFF', backgroundColor: '#2a205e', borderRadius: '3px', borderWidth: '0px' }} form="sib-form" type="submit">
                                        <svg className="icon clickable__icon progress-indicator__icon sib-hide-loader-icon" viewBox="0 0 512 512">
                                            <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                                        </svg>
                                        Enviar
                                    </button>
                                </div>
                            </div>
                            <input type="text" name="email_address_check" defaultValue className="input--hidden" />
                            <input type="hidden" name="locale" defaultValue="es" />
                        </form>
                    </div>
                </div>
            </div>
            {/* END - We recommend to place the below code where you want the form in your website html  */}
            {/* START - We recommend to place the below code in footer or bottom of your website html  */}
            {/* END - We recommend to place the above code in footer or bottom of your website html  */}
            {/* End Sendinblue Form */}
        </div>
    )
}