import React from 'react'
import { Container, Flex, Box, css, Text } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/ContentImages'
import QuickSignupForm from '@solid-ui-components/QuickSignupForm'
import ContentButtons from '@solid-ui-components/ContentButtons/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ListItem from '@solid-ui-components/ListItem'

const styles = {
  items: {
    display: 'none',
      '@media (max-width: 860px)': {
        display: 'block'
      },
  },
  border: {
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: '#2A205E',
    '@media (max-width: 765px)': {
      fontSize: 30,
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  contentTitle : { 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 991px)': {
      justifyContent: 'center',
      alignItems: 'baseline',
    },
    '@media (max-width: 765px)': {
      justifyContent: 'flex-start',
    },
  },
  line: {
    borderLeft: '5px solid red'
  },
  subTitle: { 
    fontSize: '18px',
    paddingLeft: '2rem'
  },
  text: {
    '@media (max-width: 1014px)': {
      marginTop: '4rem'
    }
  }
}

const HeroBlock01 = ({
  content: { text = [], images, buttons, form, icon, collection },
  reverse
}) => (
  <Container >
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          reverse ? 'column' : `column-reverse`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `3/5`],
          [reverse ? 'ml' : 'mr']: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`,

        }}
      >
        <ContentImages
          content={{ images }}
          loading='eager'
          reverse={reverse}
          imagePosition='center'
        />
      </Box>
      <Box
        sx={{
          flexBasis: `2/5`,
          textAlign: [`left`, 'left', 'center', `left`]
        }}
      >
        <Box sx={styles.text}>
          <Reveal effect='fadeInDown'>
          <Box sx={styles.contentTitle}>
            <Icon content={icon} mr='1' size='10px' round />
              <Text sx={styles.title}>
                Confían en <span style={{ fontWeight: 600 }}>nosotros</span>
              </Text>
          </Box>
              <Text sx={styles.subTitle}>Hemos sido distinguidos por los organismos internacionales más reconocidos en materia de Edtech, quienes nos destacan por el cambio de paradigma y la innovación de nuestros productos, el impacto que genera sobre las comunidades y la mejora continua de nuestros contenidos.</Text>
            <ContentText content={text} />
          </Reveal>
        </Box>
        {collection && (
          <>
          <Box sx={styles.items}>
            <Divider space={3} />
            <Reveal
              effect={reverse ? 'fadeInRight' : 'fadeInLeft'}
              duration={1.5}
              >
              {collection.map((props, index) => (
                <>
                  <ListItem key={`item-${index}`} {...props} />
                  <Divider space={2} />
                </>
              ))}
            </Reveal>
          </Box>
          </>
        )}
        {buttons && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0] })}
          >
            {buttons && (
              <>
                <Divider space={4} />
                <ContentButtons content={buttons} />
              </>
            )}
          </Reveal>
        )}
        {form && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0] })}
          >
            <QuickSignupForm {...form} space={3} />
          </Reveal>
        )}
      </Box>
    </Flex>
  </Container>
)


export default WithDefaultContent(HeroBlock01)
